import React, { Component } from 'react';
import styles from './contact.module.css';
import firebase from './firebase';

class Contant extends Component {

    state = {

        fullName: '',
        email: '',
        message: '',
        status: false,
        btniconstyle: styles.btnicon,
        btnstyle: styles.btnstyle

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    addMessage = (e) => {
        e.preventDefault();

        const { fullName, email, message, status } = this.state;

        if (message && message.length < 50) {
            alert('please enter at least 50 characters');
            return;
        }

        if (!fullName || !email || !message) {
            alert('please enter all the fields');
        }
        else {

            firebase.firestore().collection('messages').add({
                fullName,
                email,
                message,
                status
            }).then((docRef) => {
                alert('Your Message has been received. We will get in touch with you soon.');
                this.setState({
                    fullName: '',
                    email: '',
                    message: ''
                });

            })
        }
    }

    render() {
        return (
            <div className="container my-5" id="contact">
                <div className={`row p-5 ${styles.top}`}>

                    <div className={`col-md-6 my-5 ${styles.custom}`}>
                        <div>
                            <h1 className={`${styles.headingfont}`}>Become the next</h1>
                            <h1 className={`${styles.headingfont}`}>success story!</h1>
                        </div>

                    </div>


                    <div className={`col-md-6 ${styles.card}`}>
                        <div className={`${styles.form}`}>

                            <form className="p-5">
                                <div className="form-group">

                                    <label className="text-white">Name</label>

                                    <input
                                        type="text"
                                        className={`form-control ${styles.input}`}
                                        name="fullName"
                                        value={this.state.fullName}
                                        onChange={this.handleChange}
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Name" />

                                </div>
                                <div className="form-group">

                                    <label className="text-white">Email Address</label>

                                    <input
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        className={`form-control ${styles.input}`}
                                        placeholder="Enter Email" />

                                </div>


                                <div className="form-group">
                                    <label className="text-white">Message</label>
                                    <textarea
                                        onChange={this.handleChange}
                                        name="message"
                                        value={this.state.message}
                                        placeholder="Enter Your Message"
                                        className={`form-control ${styles.input}`}
                                        rows="5"></textarea>
                                </div>

                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className={`py-3 ${this.state.btnstyle}`}
                                        onClick={this.addMessage}
                                        onMouseEnter={() => this.setState(
                                            {
                                                btnstyle: styles.hoverbtnstyle,
                                                btniconstyle: styles.hoverbtnicon
                                            }
                                        )}

                                        onMouseLeave={() => this.setState(
                                            {
                                                btniconstyle: styles.btnicon,
                                                btnstyle: styles.btnstyle
                                            }
                                        )}
                                    >Send
                                        <i className={`fas fa-arrow-right ${this.state.btniconstyle}`}></i>
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Contant
