import { Link } from "react-scroll"
import PropTypes from "prop-types"
import React from "react"
import { Component } from "react"
import styles from './main.module.css'

class Main extends Component {
  state = {
    btnstyle: styles.btnstyle,
    btniconstyle: styles.btnicon
}

handleHoverBtn  = () => {
    this.setState({
        btnstyle: styles.hoverbtnstyle,
        btniconstyle: styles.hoverbtnicon
    });
}

handleHoverRemove = () => {
    this.setState({
        btnstyle: styles.btnstyle,
        btniconstyle: styles.btnicon
    });
}

render() {
    return (
        <div className={`${styles.back} ${styles.fullscreen}` } id="main">
            <div className="container d-flex justify-content-between text-white">
                <div>
                    <h1 className={`${styles.headingfont}`}>Innovation 
                        <span className={`${styles.fullstop}`}>.</span> 
                    </h1>

                    <h1 className={`${styles.headingfont}`}>Value 
                        <span className={`${styles.fullstop}`}>.</span>
                    </h1>

                    <h1 className={`${styles.headingfont}`}>Delivered 
                        <span className={`${styles.fullstop}`}>.</span>
                    </h1>

                    < button
                    className=  {`mt-4 ${this.state.btnstyle}`} 
                    onMouseEnter={this.handleHoverBtn}
                    onMouseLeave={this.handleHoverRemove}
                    >
                      <Link to="contact" smooth={true} duration={1000}> Get in Touch <i className={`fas fa-arrow-right ${this.state.btniconstyle}`}></i></Link> 
                    </button>
                </div>

                <div>
                   
                </div>

            </div>
        </div>
    )
}
}




export default Main
