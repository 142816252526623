import { nanoid } from 'nanoid';

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'virusclusters.webp',
    imgDetail: 'fusion-medical-animation-EAgGqOiDDMg-unsplash.webp',
    title: 'Quantification of virus clusters',
    shortDesc: 'Analyse and interpret Microscope images of Viruses',
    mainDesc: `
    <div class="description-content">
      <p>For the Biotech company HOOKIPA Pharma we developed an automated image analysis application for virus clusters.</p>
      <p>The biggest challenge was the complexity of the image analysis. We faced a wide heterogeneity of the cluster appearance, dependencies between images and several business rules that define a cluster.</p>
 
      <p>After the images were taken by a fully automated microscope our applications detected, transformed and then uploaded them to the cloud. There the images were analysed by our customised algorithms and the results as well as the images were visualised in a WebUI. Additionally, the images and the results could be modified in the WebUI.</p>
      <p>In this project we aimed to deliver the results of the image analysis a few seconds after it was taken by the microscope. We achieved this with machine learning approaches and a highly scaleable and decoupled microservice architecture running in Azure Kubernetes.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'energy.webp',
    imgDetail: 'energy_dashboard.webp',
    title: 'Monitoring of Powerplants',
    shortDesc: 'Monitoring of Sensorvalues of various Powerplants',
    mainDesc:
      `
    <div class="description-content">
      <p>For the CONENGA Group we build a monitoring and notification solution for multiple power plants in their care.</p>
      <p>Real-time data is transmitted from hundreds of sensors from the plants. The data is processed, interpreted and visualised. Besides showing the data of the sensors, it is also possible to wrap them into formulas and calculate complex functions. Those new results can be used again in formulas and be visualised.</p>
      <p>Custom built notification and reportingservices inform the CONENGA Group and their customers in real-time based on that data.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'digital-transformation.webp',
    imgDetail: 'digital-transformation-detail.webp',
    title: 'Analytical Sample Processing',
    shortDesc: 'Development and Operation of Analytical Sample Processing System',
    mainDesc: `
    <div class="description-content">
      <p>For the Biotech company HOOKIPA Pharma we developed a System helping them to organize analytical samples throughout the whole testing process.</p>
      <p>It allows a collaboration between requestors and operators over the whole company and eases the daily workflow of the analytical team.</p>
      <p>With this step, the company could get rid of the usage of error-prone Excel sheets that were previously used.</p>
      <p>Besides the sample tracking and release of the results during the analytical process, the system also offers multiple options to export and transform the data. Furthermore, it allows an insight into sample turnover times and other detailed statistics for management.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'audio.webp',
    imgDetail: 'audio-detail.webp',
    title: 'Automatic Audio Mastering',
    shortDesc: 'Automatic Audio Mastering',
    mainDesc: `
    <div class="description-content">
      <p>The goal of this product called 'Masterlizer' was to optimise audio ('master') in real-time in the cloud. Several thousand users were using the application.</p>
      <p>They could upload their songs and without any precalculation of the audio they could listen to the optimised version of it at once. Also small tweaks could be applied by them in real-time. The services also focused on audio quality, why the audio was calculated and transmitted without any compression (WAV).</p>
      <p>Besides the application itself, we built a scaleable cloud infrastructure and managed to keep the costs at a minimum although uncompressed audio data was processed in real-time for hundreds of users.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'truck.webp',
    imgDetail: 'truck-detail.webp',
    title: 'Transport planning',
    shortDesc: 'Creation of automatic Transport planning Application',
    mainDesc: `
    <div class="description-content">
      <p>For one of europe's largest logistic companies, a system was architectured and built to support them in the planning of their truck transports. Real-time data over KafkaStreams were passed to Machine-Learning Algorithms to calculate the best match between goods and trucks.</p>
      <p>Millions of calculations are performed by this application on a daily basis, to adjust the suggestions to new circumstances and therefore lead to the best possible result.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'app-for medical-device.webp',
    imgDetail: 'app-for medical-device-detail.webp',
    title: 'Apps for Medical Devices',
    shortDesc: 'Apps for Medical Devices',
    mainDesc: `
    <div class="description-content">
      <p>For the Medtech Startup Tremitas GmbH, we developed a mobile app for their medical product, the Tremipen. This device measures the tremor of the hand precisely generating essential values for the further treatment of the patient.</p>
      <p>We built a mobile App interacting with their device. The data from the Tremipen is tracked, visualised and can be transmitted to doctors and partners. Besides the App we built a highly scaleable cloud infrastructure for the backend.</p>
      <p>As we were dealing with sensitive information we had to especially focus on security and data integrity.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'cloud-architecture.webp',
    imgDetail: 'cloud-architecture-detail.webp',
    title: 'Cloud Architecture',
    shortDesc: 'Designing and Building your Infrastructure in the cloud',
    mainDesc: `
    <div class="description-content">
      <p>For the Global Rockstar GmbH we updated, optimised and secured their whole company infrastructure. This included on the one hand their cloud services from AWS, their whole application lifecycle and deployment processes and of course the IT in the company office.</p>
      <p>In the End the costs of the company for their IT could be reduced by about 75%. We implemented state-of-the-art security mechanisms and their application lifecycle was optimised resulting in higher productivity of the development team.</p>
      <p>Despite these huge changes the daily business and productivity of the teams was not impacted in any kind.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'communication.webp',
    imgDetail: 'communication-detail.webp',
    title: 'Notification system',
    shortDesc: 'Sending Millions of Messages to Customers and Partners',
    mainDesc: `
    <div class="description-content">
      <p>Based on configured business rules, a system was architectured and built to listen to millions of real-time events and interpret them. Based on the result, notifications like emails, sms, webhooks, etc. were triggered and sent to customers and partners.</p>
      <p>Besides the building of a scalable and fault tolerant solution for this task, especially the traceability was a big challenge that was solved by detailed monitoring of the decision tree of the application.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'legacy.webp',
    imgDetail: 'legacy-detail.webp',
    title: 'Transforming legacy software',
    shortDesc: 'Transforming legacy software to the new world',
    mainDesc: `
    <div class="description-content">
      <p>We helped several customers to transform their legacy applications into modern, state-of-the-art software solutions.</p>
      <p>Some applications we worked with date back until the mid 90s. Transforming them into being able to handle today's business requirements is a huge challenge.</p>
      <p>We achieved that by focusing on the business and its processes by DDD (Domain Driven Design) and split the monoliths based on that information. The result was scalable and decoupled microservice architecture.</p>
    </div>
    `
  },

  {
    id: nanoid(),
    img: 'webshop.webp',
    imgDetail: 'webshop-detail.webp',
    title: 'Webshop Systems',
    shortDesc: 'Webshop Systems',
    mainDesc: `
    <div class="description-content">
      <p>For multiple clients we developed and extended multiple different webshop systems. This included writing custom shops from scratch and also working on large existing systems with about ~1.5 purchases / sec.</p>
    </div>
    `
  },
  {
    id: nanoid(),
    img: 'education.webp',
    imgDetail: 'e-learning.webp',
    title: 'E-Learning Platform',
    shortDesc: 'E-Learning Platform for deaf students',
    mainDesc: `
    <div class="description-content">
      <p>Based on a scientific study we developed a platform for deaf students and their teachers. We aimed to support both parties in exchanging information and teaching materials.</p>
      <p>This project became part of a large project sponsored by the European Union and was used in a cooperation of multiple universities between Austria, Germany and Switzerland.</p>
    </div>
    `
  }
];

