import { nanoid } from 'nanoid';

// Services DATA
export const ServicesData = [
  {
    id: nanoid(),
    title: 'Solution Architecture & System Design',
    desc: `
    <p>By strongly focusing on the business and the processes of our customers, we help to evaluate their requirements and how IT and Software Solutions can enable them to reach their goals.</p>
    <p>We design and deploy state-of-the-art system architectures, from infrastructure to the application itself, that are capable of handling todays challanges.</p>
    `
  },
  {
    id: nanoid(),
    title: 'Agile Software Development',
    desc: `
    <p>We are experts in agile software development and work closely together with our customers to create solutions tailored to their needs and add value to their business fast.</p>
    <p>The setup of secure build pipelines, load testing, chaos engineering, operation of the application and the infrastructure are as important to us as the development itself.</p>
    `
  },
  {
    id: nanoid(),
    title: 'Cloud Services & Serverless Computing',
    desc: `
    <p>When it comes to Cloud computing, we can assist our customers with broad and detailed knowledge about all major cloud providers.</p>
    <p>We can support our customers regarding all cloud related tasks. <br/>For example migration of OnPremise Services to the Cloud; building a Hybrid/MultiCloud infrastructure or supporting them on how to get the most out of available cloud services.</p>
    `
  },
  {
    id: nanoid(),
    title: 'Machine Learning & Data Engineering',
    desc: `
    <p>Based on the specific requirements we develop Machine Learning Models and Algorthims and further enable our customers to implement and use these Artificial Intelligence Technologies in their services and products.</p>
    `
  },
  {
    id: nanoid(),
    title: 'Digital Transformation & Process Optimisation',
    desc: `
    <p>Together with our customers we analyse their current business processes to optimise and transform them into lean and robust business solutions.</p>
    `
  }
];