import React, { Component } from 'react';
import border from '../../images/border.png';


const Icon = props =>{
    return (
        <div>
           <h1>
               <i className={`fab fa-${props.iconName}`}></i>
            </h1> 
        </div>
    );       
}



class Client extends Component {


    state = {
        icons: ["aws","google","slack","twitter","cc-paypal"]
    }

    render() {
        return (
            <div className="container my-5" id="client">
                     <div className="d-inline-block">
                        <h1>Clients</h1>
                        <div className="text-center">
                            <img 
                                alt="border"
                                src={border}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-around my-5">
                        {
                            this.state.icons.map((val,ind)=>{
                                return (
                                    <Icon 
                                    key={Math.random()}
                                    iconName={val}
                                    />

                                );
                            })
                        }
                    </div>
            </div>
        )
    }
}

export default Client;
