import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from './../components/Main/Main'
import About from './../components/About/About'
import Services from './../components/Services/Services'
import Client from './../components/Clients//Client'
import Contact from './../components/Contact/Contact'
import Footer from './../components/Footer/Footer'
import Portfolio from './../components/Portfolio/Portfolio'
import { Helmet } from "react-helmet"
import Header from "../components/header"



const IndexPage = ({ data }) => {
  return (

    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css" integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA==" crossorigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@100&display=swap" rel="stylesheet" />
      </Helmet>
      <Layout>
        <SEO title="archITex Solutions GmbH" />
        <Header />
        <Main />
        <About />
        <Services />
        <Portfolio />
        {/*<Client />*/}
        <Contact />
        <Footer />
      </Layout>
    </>
  )
}

export default IndexPage
