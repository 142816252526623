import React, { Component } from 'react';
import styles from './footer.module.css';
import logo from './../../images/logolight.webp'

export class Footer extends Component {


    state = {
        icons: ["linkedin", "twitter", "github", "google"],
        currentYear: new Date().getFullYear(),
    }

    componentDidMount() {
        this.updateYearInHtml();
    }

    updateYearInHtml = () => {
        const originalContent = "© archITex Solutions GmbH 2021, All rights reserved";

        // Replace the year with the current year
        const updatedContent = originalContent.replace(/2021/g, this.state.currentYear);

        // Update the state with the new content
        this.setState({ updatedContent });
    };

    render() {
        return (
            <div className={`${styles.back} text-center`}>
                <div className="py-3">

                    <div className="mt-5 text-white">
                        <img alt="logoimg" style={{ height: '5vh' }} src={logo} />
                    </div>

                    <div className="mt-3 text-white">
                        <small dangerouslySetInnerHTML={{ __html: this.state.updatedContent }} />
                        <p className="impressum-text" data-toggle="modal" data-target={'.impressum'}>Impressum</p>

                    </div>

                </div>

                <div
                    className={"modal fade bd-example-modal-lg impressum"}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true">

                    <div className="modal-dialog modal-lg">

                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" style={{ position: "absolute", right: "3%", zIndex: '999 ' }}
                                    className="close float-right"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="impressum-content">
                                    <p>archITex Solutions GmbH<br />Obersdorferstraße 29/2/5 <br />2120 Wolkersdorf im Weinviertel <br />Österreich</p>
                                    <p><strong>Unternehmensgegenstand:</strong> IT Dienstleistungen<br /><strong>UID-Nummer:</strong> ATU76522719<br /><strong>Firmenbuchnummer:</strong> FN 549638 t<br /><strong>Firmenbuchgericht:</strong> Landesgericht Korneuburg<br /><strong>Firmensitz:</strong> politische Gemeinde Mistelbach</p>
                                    <p><strong>Tel.:</strong> +43 650 6341734<br /><strong>E-Mail: </strong> <a href="mailto:office@architex.solutions">office @architex.solutions</a></p>
                                    <p><strong>Mitglied bei:</strong> WKO</p>
                                    <p><strong>Anwendbare Rechtsvorschriften:</strong> Gewerbeordnung: <a target="_blank" href="https://www.ris.bka.gv.at">www.ris.bka.gv.at</a></p>
                                    <p><strong>Aufsichtsbehörde/Gewerbebehörde:</strong>Bezirkshauptmannschaft Mistelbach</p>
                                    <p><strong>Verleihungsstaat:</strong> Österreich</p>
                                    <p><strong>Geschäftsführer</strong><br />Martin Tschemernjak</p>
                                    <p><strong>Angaben zur Online-Streitbeilegung:</strong><br />Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: <a target="_blank" href="http://ec.europa.eu/odr">ec.europa.eu/odr</a>. <br />Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
