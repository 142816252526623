import React, { Component, useState } from 'react';
import border from './../../images/border.png';
import './Portfolio.css';
import Image from './portfolioImage'
import { projectsData } from './../../mock/portfolioData';


const PortfolioCard = props => {
    const [titleClass, setTitleClass] = useState('card-title');
    return (
        <div className="col-md-6 ">
            <div className="my-5">
                <div

                    className="p-cards shadow"
                    onMouseEnter={() => setTitleClass('aft-Card-title')}
                    onMouseLeave={() => setTitleClass('card-title')}
                >
                    <Image alt="123" filename={props.ImageTitle} />
                    <div className="card-body">
                        <h5 className={`${titleClass}`}>{props.title}</h5>
                        <div className="overlay ">

                            <p className="card-text">{props.shortDesc}</p>
                            <button className="overlay-btn" type="button" data-toggle="modal" data-target={'.' + props.index}>
                                view more <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className={"modal fade bd-example-modal-lg " + props.index}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true">

                <div className="modal-dialog modal-lg">

                    <div className="modal-content">
                        <div className="modal-body">

                            <div className="row">
                                <div className="col-md-12">
                                    <button type="button" style={{ position: "absolute", right: "3%", zIndex: '999 ' }}
                                        className="close float-right"
                                        data-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="text-center font-weight-bold">{props.title}</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Image alt="123" className="detail-image" filename={props.ImageDetailTitle} />
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center" dangerouslySetInnerHTML={{ __html: props.mainDesc }}></div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}



class Portfolio extends Component {

    render() {

        return (
            <div className="container my-5" id="portfolio">
                <div className="d-inline-block">
                    <h1>Portfolio</h1>

                    <div className="text-center">
                        <img
                            alt="border"
                            src={border}
                        />
                    </div>
                </div>
                <div className="row">
                    {
                        projectsData.map((val, index) => {
                            return (
                                <PortfolioCard
                                    index={'element-' + index}
                                    key={val.id}
                                    title={val.title}
                                    shortDesc={val.shortDesc}
                                    mainDesc={val.mainDesc}
                                    ImageTitle={val.img}
                                    ImageDetailTitle={val.imgDetail}
                                    header="Background.webp"
                                />
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}






export default Portfolio;
