import React, { Component, useState } from 'react';
import border from './../../images/border.png';
import styles from './services.module.css';
import Carousel from 'react-elastic-carousel'
import { ServicesData } from './../../mock/servicesData';



const ServiceCard = props => {

    const [fontColor, setFontColor] = useState('dark')
    const [backGround, setBackGround] = useState('')

    const handleHover = () => {
        setBackGround(styles.back);
        setFontColor('white');
    }


    const hoverOut = () => {
        setBackGround('');
        setFontColor('dark')
    }

    return (
        <div className="px-2">
            <div
                className={`carusell-item my-5 ${styles.card} ${backGround}`}
                onMouseEnter={handleHover}
                onMouseLeave={hoverOut}>

                <div className="py-5 px-5">
                    <h1 className={`${styles.headingfont} font-weight-bold text-${fontColor}`}>
                        {props.serviceName}
                    </h1>

                    <div className={`service-text mt-5 ${styles.paragraphfont}`} dangerouslySetInnerHTML={{ __html: props.description }}></div>
                </div>
            </div>
        </div>
    );
}

class Services extends Component {

    state = {
        linkstyle: null,
        iconStyle: styles.icon
    }


    render() {
        const breakPoints = [
            { width: 550, itemsToShow: 1 },
            { width: 768, itemsToShow: 2 },
            { width: 1200, itemsToShow: 3 },
        ];
        return (
            <div className="container my-5" id="services">
                <div className="d-inline-block">
                    <h1>Services</h1>
                    <div className="text-center">
                        <img
                            alt="border"
                            src={border}
                        />
                    </div>
                </div>


                {/* <div className="mt-5 float-right">
                    <a

                    className={`text-dark ${this.state.linkstyle}`} 
                    onMouseEnter={()=>this.setState({
                        linkstyle: styles.hoverlink,
                        iconStyle: styles.hovericon
                    })}

                    onMouseLeave={()=>this.setState({
                        linkstyle: null,
                        iconStyle:styles.icon
                        
                    })}

                    href="#">
                         Explore Services 
                        <i className={`${this.state.iconStyle} fas fa-long-arrow-alt-right`}></i>
                    </a>
                </div> */}

                <Carousel breakPoints={breakPoints}>

                    {
                        ServicesData.map((val, ind) => {
                            return (
                                <ServiceCard
                                    key={val.id}
                                    serviceName={val.title}
                                    description={val.desc}

                                />
                            )
                        })
                    }

                </Carousel>



            </div>
        )
    }
}

export default Services
