import React, { Component } from 'react';
import styles from './about.module.css';
import border from '../../images/border.png';
import { AboutData } from './../../mock/aboutData';
import Image from '../Portfolio/portfolioImage'


const AboutCard = props => {
    return (
        <div>
            <div className={`${styles.card} col-md-12 mt-5`}>
                <div dangerouslySetInnerHTML={{ __html: props.text }} className={`p-4 text-white ${styles.paragraph}`}>
                </div>
            </div>
        </div>
    );
}



class About extends Component {
    render() {
        return (
            <div className="container my-5" id="about">
                <div className="d-inline-block">
                    <h1>About archITex Solutions</h1>

                    <div className="text-center">
                        <img
                            alt="border"
                            src={border}
                        />
                    </div>
                </div>
                <AboutCard text={AboutData.paragraph} />
            </div>
        )
    }
}

export default About;
