import { firebase } from '@firebase/app';
import 'firebase/firestore';

const settings = { timestampsInSnapshots: true };

const config = {
    apiKey: "AIzaSyCBQtj_2Jhj7aYkVX7sFMpRWrTdq9RjQFg",
    authDomain: "portfolio-83c5b.firebaseapp.com",
    projectId: "portfolio-83c5b",
    storageBucket: "portfolio-83c5b.appspot.com",
    messagingSenderId: "552834963366",
    appId: "1:552834963366:web:9b3cbb512906aa40932167",
    measurementId: "G-WK8M7WM7M8"
};

firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;
